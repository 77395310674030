import React from 'react';
import PropTypes from 'prop-types';

const PageContainer = ({ children }) => {
  return (
    <div className='page-container'>
               <section>
        <h1>Hello Gaming Enthusiasts!</h1>
        <p>We're thrilled to introduce you to Ludee Entertainment, a dynamic game development studio that's rapidly expanding its horizons in the gaming industry!</p>
    </section>

    <section>
        <h2>Why Ludee Entertainment?</h2>
        <ul>
            <li><strong>Innovation:</strong> We pride ourselves on pushing boundaries and bringing fresh ideas to the gaming landscape.</li>
            <li><strong>Diverse Creations:</strong> From action-packed adventures to thought-provoking simulations, our diverse portfolio has something for every gamer.</li>
            <li><strong>Community Engagement:</strong> Your feedback matters. We believe in creating games that resonate with our community, and your input helps shape our creations.</li>
        </ul>
    </section>

    <section>
        <h2>Stay Connected:</h2>
        <p>We invite you to be part of our exclusive community! By providing your email, you'll gain access to:</p>
        <ul>
            <li><strong>Exciting Announcements:</strong> Be the first to hear about our latest game releases and major updates.</li>
            <li><strong>Behind-the-Scenes:</strong> Get a sneak peek into the development process and see how your favorite games come to life.</li>
            <li><strong>Exclusive Content:</strong> Access special in-game items, discounts, and other perks reserved for our dedicated community.</li>
        </ul>
        <p>Join us on this thrilling adventure by leaving your email. Be among the first to receive updates on our ongoing projects, upcoming releases, and other exciting news from Ludee Entertainment.</p>
    </section>
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;