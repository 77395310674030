import React from "react";
import { useSearchParams } from "react-router-dom";
import AuthContainer from "../components/auth/AuthContainer";
import PageContainer from "../components/page-container/PageContainer";
import Header from "../components/header/Header";

const Login = (props) => {
  // getting and setting URL params
  const [searchParams, setSearchParams] = useSearchParams();

  // get action from URL
  const action = searchParams.get("action") || "login";

  // used to set initial state
  let indexFromUrl = 0;
  if (action === "register") {
    indexFromUrl = 1;
  }

  return (

    <>
      <Header>
        <AuthContainer />
      </Header>
      
      <PageContainer>


      </PageContainer>

    </>

  );
};

export default Login;
