import { useEffect } from "react";
import Logout from "../components/auth/Logout";
import PageContainer from "../components/page-container/PageContainer";
import Header from "../components/header/Header";
// import { User } from "firebase";

const Home = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(User)

  return (
    <div>
      <Header>
        <Logout />
      </Header>
      <PageContainer>
      </PageContainer>
    </div>
  );
};

export default Home;
