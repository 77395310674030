import React from 'react';
import PropTypes from 'prop-types';

const Header = ( props ) => {
    
  return (
    <div style={styles.header}>
        {props.children}
    </div>
  );
};

Header.propTypes = {
  props: PropTypes.string
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    
    // alignItems: 'center',
    // position: 'fixed',
    // zIndex: '99',
    // width: '100%'
  }
};

export default Header;
